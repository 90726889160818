import axios from "axios";
import { ElMessage } from "element-plus"
import { reactive, ref, Ref, watch } from 'vue'
import { QuestionRepository } from '@/repositories/QuestionRepository'

export type QuestionRecord = {
  body: string;
  answer: string;
  question_category_id: number;
}

export const useExcelUpload: Function = (projectId: number) => {
  const confirmDialogState: {
    visible: boolean;
    data: QuestionRecord[];
  } = reactive({
    visible: false,
    data: [],
  });

  const successMessage: Ref<string> = ref<string>('');
  const errorMessage: Ref<string> = ref<string>('');

  const handleSuccess = (response: any) => {
    confirmDialogState.data = response;
    confirmDialogState.visible = true;
  };

  const handleError = (errors: any) => {
    const error: string = JSON.parse(errors.message).join("\r\n");

    const blob = new Blob(
      [error],
      { "type": "text/plain" }
    );

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'ERROR.txt'
    link.click()

    errorMessage.value = "Q&Aのインポートに失敗しました。ダウンロードされたERROR.txtファイルの内容をご確認いただき、Excelファイルを修正して再度お試しください。";
  };

  const handleSubmit = (): void => {
    try {
      const repository = new QuestionRepository(projectId);
      repository.bulkAdd(confirmDialogState.data);
      successMessage.value = 'Q&Aのインポートに成功しました。';
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response) {
        errorMessage.value = `Q&Aのインポートに失敗しました。再読み込みをしてお試し下さい(status: ${e.response.status})`;
      }
    }

    confirmDialogState.visible = false;
    confirmDialogState.data = [];
  };

  watch(successMessage, () => {
    if (successMessage.value === "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: successMessage.value,
      type: 'success'
    });
    successMessage.value = "";
  });

  watch(errorMessage, () => {
    if (errorMessage.value === "") {
      return;
    }
    ElMessage({
      showClose: true,
      message: errorMessage.value,
      type: 'error'
    });
    errorMessage.value = "";
  });

  return {
    confirmDialogState,
    handleSuccess,
    handleError,
    handleSubmit,
  };
};
